import React from 'react';

import logo from './../assets/logo-full.png';

export const Navbar = () => {
    return (
        <nav className = " navbar navbar-expand-lg navbar-light bg-light sticky-top ">
            <div className = " container-fluid ">
                <a className = " navbar-brand " href="#">
                    <img 
                        src = { logo }
                        alt = 'Avluet' width = '110' height = '30' 
                        className = "d-inline-block align-text-top" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className = " navbar-nav ms-auto ">
                        <li className = ' nav-item '>
                            <a className = 'nav-link active' aria-current = 'page' href = '/'>Inicio</a>
                        </li>
                        <li className = ' nav-item '>
                            <a className = 'nav-link ' aria-current = 'page' href = '/#servicios'>Servicios</a>
                        </li>
                        <li className = ' nav-item '>
                            <a className = 'nav-link ' aria-current = 'page' href = '/#productos'>Productos</a>
                        </li>
                        <li className = ' nav-item '>
                            <a className = 'nav-link ' aria-current = 'page' href = '/#contacto'>Contacto</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

    )
}
