import React from 'react';

import logo from './../assets/logo-full.png'

export const Footer = () => {
    return (
        <div className = ' footer_container background_light '>
            <div className = ' row mx-0 p-5 '>
                <div className = ' col-12 col-md-4 col-lg-3 mb-5 mb-md-0 '>
                    <img 
                        className = ' footer_logo '
                        src = { logo }
                    />
                </div>
                <div className = ' col-12 col-md-4 col-lg-2 footer_links-container '>
                    <a href = '/#'>Inicio</a><br />
                    <a href = '/#servicios'>Servicios</a><br />
                    <a href = '/#productos'>Productos</a><br />
                    {/* <a href = '#'>Portafolio</a><br /> */}
                    <a href = '/#contacto'>Contacto</a><br />
                </div>
                <div className = ' col-12 col-md-4 col-lg-2 footer_links-container '>
                    <a href = 'https://www.facebook.com/avluet' target = '_blank'>Facebook</a><br />
                    <a href = 'https://www.instagram.com/avluet/' target = '_blank'>Instagram</a><br />
                </div>
                <div className = ' col-12 col-md-4 col-lg-2 footer_links-container '>
                    <a href = '#'>Términos y Condiciones</a><br />
                    <a href = '#'>Políticas de Privacidad</a><br />
                </div>
                <div className = ' col-12 col-md-4 col-lg-3 '></div>
            </div>
        </div>
    )
}
