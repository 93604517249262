import React from 'react'

export const Button = ({ type, text, link, blank = false }) => {
  return (
    <a
        className = {` button_${ type } `}
        href = { link }
        target = { blank ? '_blank' : '_self' }
    >
        { text }
    </a>
  )
}
