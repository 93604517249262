import React from 'react'
import { Button } from './Button'

export const Service = ({ image, title, description = '', inverted = false, link = '#' }) => {
    return (
        <div className = ' row mx-0 p-1 p-md-5 service_container '>
            <div className = ' col-12 col-md-6 d-flex aling-items-center justify-content-center mb-4 mb-md-0 '>
                <img 
                    className = ' service_image '
                    src = { image }
                />
            </div>
            <div 
                className = {` 
                    col-12 col-md-6 px-3 d-flex justify-content-center flex-column 
                    order-last mb-5 mb-md-0 align-items-center align-items-md-start
                    ${ !!inverted ? 'order-md-first' : 'order-md-last' }
                `}
            >
                <p className = ' service_title  text_bold '>{ title }</p>
                <p className = ' service_description mb-5 '>{ description }</p>
                <Button 
                    text = 'Me interesa'
                    type = 'primary'
                    link = { link }
                />
            </div>
        </div>
    )
}
