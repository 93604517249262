import React from 'react';
import { Button } from '../components/Button';

import cover from './../assets/games-conference.png'
import plantilla from './../assets/plantilla-conferencia.pdf';
import presentacion from './../assets/presentacion.pdf';

export const GamesConference = () => {
  return (
    <div>
        <section className = ' games_header '>
            <p className = ' games_event mb-0 '>CONFERENCIA</p>
            <p className = ' h1 '>DE IDEA A VIDEOJUEGO</p>
            <p className = ' games_author '>IS Sofía Verónica Ro - Director Creativo</p>
        </section>

        <section>
            <div className = ' row mx-0 p-5 p-md-5 '>
                <div className = ' col-12 col-md-6 p-0 p-md-5 games_center-vertical '>
                    <p className = ' h3 games_title mb-4 text-start text-md-center '>DESCARGA EL MATERIAL</p>
                    <p className = ' games_description '>
                        Ta agradecemos por haber participado en nuestra conferenica 'De idea a videojuego'. 
                        Te compartimos el material que te platicamos en la conferencia para que continues tu 
                        camino en convertirte en un gran game developer.
                    </p>
                    <div className = ' d-flex mt-4 '>
                        <Button 
                            blank = { true }
                            link = { plantilla }
                            text = 'DESCARGAR PLANTILLA'
                            type = 'primary'
                            />
                        <div className = ' mx-1 ' />
                        <Button 
                            blank = { true }
                            link = { presentacion } 
                            text = 'DESCARGAR PRESENTACIÓN'
                            type = 'secondary'
                        />

                    </div>
                </div>
                <div className = ' col-12 col-md-6 p-0 p-md-5 games_center-vertical mt-4 mt-md-0 '>
                    <img 
                        className = ' games_cover '
                        src = { cover }
                    />
                </div>
            </div>
        </section>
    </div>
  )
}
