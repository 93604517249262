import AppRouter from "./router/AppRouter";
import { Home } from "./screens/Home";

function App() {
	return (
		// <Home />
		<AppRouter />
	);
}

export default App;
