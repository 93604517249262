import React from 'react'
import { Button } from '../components/Button'
import { Service } from '../components/Service'
import { Footer } from '../components/Footer'

// Assets Imports.
import banner from './../assets/banner.png'
import us from './../assets/nosotros.png'
import services from './../assets/services.png'

import posImage from './../assets/portfolio/pos-macbookpro.png';
import webImage from './../assets/portfolio/web-macbookpro.png';
import mobileImage from './../assets/portfolio/mobile-iphone.png';
import ecommerceImage from './../assets/portfolio/ecommerce-macbook.png';
import blogImage from './../assets/portfolio/blog-macbookpro.png';
import { Navbar } from '../components/Navbar'

export const Home = () => {
  return (
    <div>
        {/* Header Section  */}
        <div className = ' row mx-0 background_light py-5 py-md-0 '>
            <div className = ' col-12 col-md-6 home_header-content-container p-5 '>
                <p className = ' home_header-title-container '>
                    <span className = ' home_header-title-bold '>DIGITALIZAMOS</span><br />
                    <span className = ' home_header-title '>TU NEGOCIO</span>
                </p>
                <p className = ' home_header-description me-0 me-md-5 '>
                    Con <span className = ' text_primary text_bold '>Avluet Software </span> 
                    darás ese paso que tanto has esperado hacia la 
                    digitalización, te ayudaremos en tus procesos y necesidades para llevar 
                    a tu empresa a un nuevo nivel nunca antes visto. ¡Descubre más! 
                </p>

                <div className = ' d-flex mt-4 justify-content-center justify-content-md-start '>
                    <Button 
                        type = 'primary'
                        text = 'Punto de Venta'
                        link = '#productos'
                    />
                    <div className = ' mx-1 '/>
                    <Button 
                        type = 'secondary'
                        text = 'Servicios'
                        link = '#servicios'
                    />
                </div>


            </div>
            <div className = ' col-12 col-md-6 home_header-image-container order-md-1 '>
                <img 
                    src = { services }
                />
            </div>
        </div>
        {/* End Header Section  */}

        {/* Services Section  */}
        <div className = 'mt-5 container ' id = 'servicios'>
            <Service 
                image = { webImage }
                title = 'Páginas web'
                inverted = { false }
                description = {`
                    Tener una página web en la actualidad es un requisito fundamental para cada negocio, es tu entrada al mundo digital y cómo tus clientes te identificaran en este nuevo mercado.

                    Analizamos tu negocio, y entendiendo tus necesidades, te asesoramos para comenzar con tu proceso de digitalización, y te apoyamos mano a mano para que pongas tu página web a volar.                
                `}
                link = 'https://wa.link/kh127t'
            />
            <Service 
                image = { mobileImage }
                title = 'Aplicaciones Móviles'
                inverted = { true }
                description = {`
                    Desarrollamos aplicaciones a la medida de tus necesidades para uno de los mercados con mayor crecimiento y potencial de la actualidad, ¡lleva tu negocio a todas partes! Nos especializamos en aplicaciones móviles nativas para cualquier tipo de dispositivo, ya sea Android o iOS para que tu negocio siga creciendo.
                `}
                link = 'https://wa.link/50awdm'
            />
            <Service 
                image = { ecommerceImage }
                title = 'E-Commerce'
                inverted = { false }
                description = {`
                    Cuando sacas un producto al mercado, ya no compites con los negocios locales, el día de hoy abrir un negocio significa competir con los gigantes marketplaces como Amazon y MercadoLibre, y con proveedores de todo el país, o incluso todo el mundo.

                    Te ayudamos a que tu negocio no se quede atrás, y compitas en este nuevo mercado online. 
                `}
                link = 'https://wa.link/yuzcez'
            />
            <Service 
                image = { blogImage }
                title = 'Blog / Newsletter'
                inverted = { true }
                description = {`
                    Mantenerte cerca de tus clientes, y convertir los que aún no lo son, es un punto fundamental en la estrategia de marketing y comercialización de tu negocio.

                    Un blog te permitirá conectar con ellos en el día a día, recabar datos como su correo electrónico para campañas de marketing, y tener un medio para que conozcan tus promociones, productos y a tu equipo, de manera profesional.
                    
                    ¡No te pierdas la oportunidad de llegar a tu cliente ideal! 
                `}
                link = 'https://wa.link/dl2a5t'
            />
        </div>
        {/* End Services Section  */}

        {/* Banner Section  */}
        <div className = ' p-1 p-md-5 mb-5 mb-md-0 '>
            <img 
                className = ' home_banner-image '
                src = { banner }
            />
        </div>
        {/* End Banner Section  */}

        {/* POS Section  */}
        <div id = 'productos'>
            <Service 
                image = { posImage }
                title = 'Punto de Venta en la Nube'
                inverted = { false }
                description = {`
                    Manejar un negocio no es tarea fácil, y encontrar un sistema que se adapte a tus necesidades específicas, lo es menos. En Avluet Software, tenemos un punto de venta en la nube, que funciona desde cualquier computadora o tablet con internet, y que podemos personalizar para conectar con las necesidades de tu negocio.

                    Con nuestro sistema de punto de venta delegar tareas, medir el crecimiento de tu negocio, y tener una mejor relación con tus clientes ya no es tarea difícil.
                `}
                link = 'https://wa.link/jdno5u'
            />
        </div>
        {/* End POS Section  */}

        {/* Us Section  */}
        <div className = ' p-1 p-md-5 mb-5 mb-md-0 '>
            <div 
                className = ' home_us-container p-5 row mx-0'
                style = {{
                    background: `url( ${ us } )`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <p className = ' col-12 h2 mb-4 '>¿Quienes somos?</p>
                <p className = ' col-12 col-md-8 col-lg-6 home_us-description '>
                    Somos una empresa de desarrollo de software a la medida. Nuestro enfoque es darte un servicio 
                    envolvente, en donde te asesoramos sobre qué producto es mejor para tu empresa, y trazamos un 
                    camino que te ayude a cubrir tus necesidades. <br /><br />

                    También, entendemos que cada negocio es un mundo, por lo que todos nuestros servicios son 
                    100% personalizables de acuerdo a lo que la empresa necesite.

                </p>
                <div className = ' col-12 mt-4 '>
                    <Button 
                        type = 'secondary'
                        text = 'Más información'
                        link = 'https://wa.link/abliux'
                    />
                </div>
            </div>
        </div>
        {/* End Us Section  */}

        {/* Contact Section  */}
        <div id = 'contacto'>
            <div className = ' row mx-0 p-1 p-md-5 mb-5 mb-md-0 '>
                <div className = ' col-12 col-md-6 px-5 '> 
                    <p className = ' h3 text_bold mb-3 text-center text-md-start '>Contáctanos</p>

                    <div className = ' mb-2 '>
                        <p className = ' text_bold mb-1 text-center text-md-start '>Teléfono</p>
                        <a className = ' text_contact-link text-center text-md-start  ' href = 'tel:6181169096'>618 116 9096</a>
                    </div>
                    <div className = ' mt-5 '>
                        <p className = ' text_bold mb-1 text-center text-md-start '>Correo Electrónico</p>
                        <a className = ' text_contact-link text-center text-md-start ' href = 'mailto:vero@avluetsoftware.com'>vero@avluetsoftware.com</a>
                        <a className = ' text_contact-link text-center text-md-start ' href = 'mailto:alejandro@avluetsoftware.com'>alejandro@avluetsoftware.com</a>
                        <a className = ' text_contact-link text-center text-md-start ' href = 'mailto:ramses@avluetsoftware.com'>ramses@avluetsoftware.com</a>
                        <a className = ' text_contact-link text-center text-md-start ' href = 'mailto:contacto@avluetsoftware.com'>contacto@avluetsoftware.com</a>
                    </div>
                    <div className = ' mt-5 '>
                        <p className = ' text_bold mb-1 text-center text-md-start '>Ubicación</p>
                        <a className = ' text_contact-link text-center text-md-start '>Durango, Dgo. México.</a><br />
                    </div>

                </div>
                <div className = ' col-12 col-md-6 d-flex aling-items-center justify-content-center '>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d116615.46345419627!2d-104.71776542587341!3d24.022839180401682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7a518bcdf73%3A0xbfdd8ea63a6c4ad4!2sDurango!5e0!3m2!1sen!2smx!4v1658264312060!5m2!1sen!2smx" 
                        width="90%" height="350" style = {{ border: 0, margin: 'auto', }} allowFullScreen="" 
                        loading="lazy" referrerPolicy="no-referrer-when-downgrade">    
                    </iframe>
                </div>
            </div>
        </div>
        {/* End Contact Section  */}
    </div>
  )
}
