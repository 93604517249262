import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { GamesConference } from "../screens/GamesConference";
import { Home } from "../screens/Home";

export default function AppRouter () {
	return (
		<Router>
		<div>
			<Navbar />
			
			<Routes>
					<Route path="/" element = { <Home /> } />
                    <Route path="/idea-videojuegos" element = { <GamesConference /> } />
			</Routes>

			<Footer />
		</div>
		</Router>
	);
}
